/* == Typography == */

html {
  font-size: 20px;
  font-weight: 300;
}

body {
  color: #1e1e1e; /* Black */
  font-family: FinnairSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* == Fonts == */

@font-face {
  font-family: 'FinnairSans';
  font-style: normal;
  font-weight: 200; /* Light */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'FinnairSans';
  font-style: normal;
  font-weight: 300; /* Book */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'FinnairSans';
  font-style: normal;
  font-weight: 400; /* Regular */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'FinnairSans';
  font-weight: 500; /* Medium */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Medium.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'FinnairSans';
  font-style: normal;
  font-weight: 700; /* Bold */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Bold.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'FinnairSans';
  font-style: italic;
  font-weight: 400; /* Regular */
  src: url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Italic.woff2') format('woff2'),
    url('https://cdn.finnair.com/fcom-ui-styles/prod/fonts/FinnairSans/FinnairSans-Italic.woff') format('woff');
}
